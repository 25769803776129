<template>
  <div class="m-auto pr-2 md:pr-4 lg:pr-8 vld-parent pb-4">

    <!-- Comment sending in progress -->
    <loading
      :active="isLoading"
      :is-full-page="false"
      :loader="'bars'"
      :color="'#00afd3'"
      :height="90"
      :width="90"
      :opacity="0"
    />

    <textarea
      v-model="$v.text.$model"
      class="resize-none w-full h-20 py-2 px-3 min-h-30 border border-gray-300 placeholder-gray-500 text-gray-700 
        rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
      placeholder="Ваш комментарий"
    >      
    </textarea>
    <div
      @click="sendClick()"
      class="cursor-pointer block mr-2 mt-3 rounded-md py-2 font-semibold border-2 text-gray-200 
        hover:text-white bg-primary-600 border-primary-600  hover:bg-primary-400 hover:border-primary-400 md:w-1/2 lg:w-1/3 mr-0 ml-auto"
    >
      Отправить
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import SolidButton from '@/components/_partials/SolidButton.vue'

export default {
  mixins: [validationMixin],
  name: 'AddComment',
  props: {
    contentId: {
      type: Number,
      required: true
    }
  },
  components: {
    SolidButton,
    Loading
  },
  data: function() {
    return {
      text: null,
      isLoading: false
    }
  },
  validations: {
    text: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(500)
    }
  },
  computed: {
    contentType() {
      return 'clinical-case'
    }
  },
  methods: {
    ...mapActions({
      sendComment: 'comments/sendComment'
    }),
    sendClick() {
      if (!this.$v.$invalid) {
        this.isLoading = true
        this.sendComment({ contentType: this.contentType, text: this.text, contentId: this.contentId })
          .then(() => {
            this.isLoading = false
          })
      }
    }
  }
}
</script>

<style>
</style>
