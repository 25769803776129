<template>
  <div class="m-auto pr-2 md:pr-4 lg:pr-8 mb-2">
    <div class="p-4 bg-white rounded-md shadow-lg text-left">
      <p><span class="font-semibold text-primary-500">Пользователь №{{ userId }}</span><br>
      <span class="font-semibold">{{ date }}</span> в 
      <span class="font-semibold">{{ time }}</span> написал(а):</p>
      <p class="pt-3">{{ text }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Comment',
  props: {
    userId:{
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    time: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style>
</style>
