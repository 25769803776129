<template>
  <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

    <!-- Loading screen -->
    <div v-if="isLoading" key="animation">
      <LoadingScreen />
    </div>

    <!-- Content -->
    <div v-if="!isLoading" key="content">
      <div class="text-left sm:pr-6 lg:pr-8 pb-3">
        <router-link class="underline text-gray-600 hover:text-primary-500" :to="{name: 'ClinicalCases'}">
          Клинический случай
        </router-link>
      </div>

      <object class="pr-2 md:pr-4 lg:pr-8">
        <embed :src="require(`@/assets/clinical-cases/` + clinicalCase.file)" width="100%" height="800"/>
      </object>

      <div v-if="commentAllowed">
        <h2 class="text-2xl pt-8 pb-4 font-medium text-gray-800 mx-auto text-left">Комментарии</h2>
        <AddComment :contentId="Number.parseInt($route.params.id)" />
        <Comment
          v-for="comment in comments"
          :key="comment.id"
          :userId="comment.userid"
          :name="comment.user"
          :date="comment.date"
          :time="comment.time"
          :text="comment.text"
        />
      </div>
    </div>
  </fade-transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'
import AddComment from '@/components/clinicalcase/AddComment.vue'
import Comment from '@/components/clinicalcase/Comment.vue'

export default {
  name: 'ClinicalCasePage',
  components: {
    FadeTransition,
    LoadingScreen,
    AddComment,
    Comment
  },
  data: function() {
    return {
      isLoading: true,
      commentAllowed: true
    }
  },
  computed: {
    ...mapState({
      clinicalCase: state => state.cases.currentCase,
      comments: state => state.comments.comments
    })
  },
  methods: {
    ...mapActions({
      getClinicalCase: 'cases/getClinicalCase',
      resetClinicalCase: 'cases/resetClinicalCase',
      getComments: 'comments/getComments'
    })
  },
  created: function() {
    // Get clinical case data
    this.getClinicalCase(this.$route.params.id)
      .then(() => {
        this.isLoading = false
      })
      .catch(error => {
        if (error.response.status == 403) this.$xmodal.open()
        this.$router.replace({ name: 'ClinicalCases' })
      })
    
    // Get comments
    this.getComments({ contentType: 'clinical-case', contentId: this.$route.params.id })
      .then(() => {

      })
  },
  beforeDestroy: function() {
    this.resetClinicalCase()
  }
}
</script>
